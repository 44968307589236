export const SIDE_LEFT = 'left'
export const SIDE_RIGHT = 'right'
export const SIDE_CENTER = 'center'

export const DAY = Symbol('DAY')
export const MONTH = Symbol('MONTH')
export const YEAR = Symbol('YEAR')
export const ARTICLE = Symbol('ARTICLE')
export const CATEGORY = Symbol('CATEGORY')
export const TAG = Symbol('TAG')
export const PAGE = Symbol('PAGE')

export const PAGE_SLUG = 'page'
